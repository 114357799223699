<template>
  <Navbar/>
  <div class="container">
    <div id="ProfileForm">
      <div class="card">
        <div class="card-header">
          <h3>Profile</h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="onSubmit">
            <div class="form-group">
              <div class="row">
                <label for="username">Username</label>
                <div class="col">
                  <input type="text" class="form-control"
                         id="username" v-model="state.username"
                         placeholder="Username" required>
                  <span v-if="username$.username.$error">
                    {{username$.username.$errors[0].$message}}
                  </span>
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-primary"
                          v-on:click="checkUsername">Update</button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <label for="email">Email</label>
                <div class="col">
                  <input type="text" class="form-control"
                         id="email" v-model="state.email"
                         placeholder="Email" required>
                  <span v-if="email$.email.$error">
                    {{email$.email.$errors[0].$message}}
                  </span>
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-primary"
                          v-on:click="checkEmail" >Update</button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <label for="password">Current Password</label>
                <div class="col">
                  <input type="password" class="form-control"
                         id="password" v-model="state.password"
                         placeholder="Current Password" required>
                  <span v-if="password$.password.$error">
                    {{password$.password.$errors[0].$message}}
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="state.password!==''">
              <div class="row">
                <label for="Newpassword">New Password</label>
                <div class="col">
                  <input type="password" class="form-control"
                         id="Newpassword" v-model="state.newPassword"
                         placeholder="Current Password" required>
                  <span v-if="password$.newPassword.$error">
                    {{password$.newPassword.$errors[0].$message}}
                  </span>
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-primary"
                          v-on:click="checkCurrentPassword">Update</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ConfirmDialog
        :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
    </ConfirmDialog>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import ConfirmDialog from 'primevue/confirmdialog';
import { computed, reactive } from 'vue';
// eslint-disable-next-line import/extensions
import {
  email, minLength, required,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Navbar from '@/components/NavBar.vue';

export default {
  name: 'ProfileForm',
  components: {
    ConfirmDialog,
    Navbar,
  },
  setup() {
    const toast = useToast();
    const state = reactive({
      username: '',
      newPassword: '',
      email: '',
      password: '',
    });
    const usernameRules = computed(() => ({
      username: [required, minLength(5)],
    }));

    const emailRules = computed(() => ({
      email: [required, email],
    }));

    const passwordRules = computed(() => ({
      password: [required, minLength(5)],
      newPassword: [required, minLength(5)],
    }));

    // const v$ = useVuelidate(rules, state);
    const username$ = useVuelidate(usernameRules, state);
    const email$ = useVuelidate(emailRules, state);
    const password$ = useVuelidate(passwordRules, state);

    return {
      toast,
      state,
      username$,
      email$,
      password$,
    };
  },
  methods: {
    getUserData() {
      this.$http.get(`https://${this.$store.state.ip}/get-user-info`, {
        headers: {
          Authorization: this.$store.state.token,
        },
      }).then((response) => {
        this.state.email = response.data.email;
        this.state.username = response.data.username;
      }).catch((error) => {
        if (error.response.status === 403) {
          this.refreshToken();
          this.getUserData();
        }
      });
    },
    refreshToken() {
      this.axios.post(`https://${this.$store.state.ip}/refresh-token`, { token: localStorage.getItem('refreshToken') }).then((response) => {
        this.$store.commit('setToken', response.data.token);
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
    },
    checkUsername() {
      this.username$.$validate();
      if (!this.username$.$error) {
        this.$confirm.require(
          {
            message: 'You will need to be re-login if successful',
            header: 'Confirmation',
            icon: 'bi bi-question-diamond',
            accept: () => {
              this.username$.$validate();
              this.axios.post(`https://${this.$store.state.ip}/update-username`,
                {
                  username: this.state.username,
                }, {
                  headers: {
                    Authorization: this.$store.state.token,
                  },
                })
                .then((response) => {
                  this.state.username = response.data.username;
                  this.getUserData();
                  this.toast.success(response.data.Message, {
                    autoHideDelay: 5000,
                    closeOnClick: true,
                  });
                  this.logout();
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    this.refreshToken();
                    this.checkUsername();
                  } else {
                    this.toast.error(error.response.data.Message, {
                      autoHideDelay: 5000,
                      closeOnClick: true,
                    });
                  }
                });
            },
          },
        );
      }
    },
    checkEmail() {
      this.email$.$validate();
      if (!this.email$.$error) {
        this.$confirm.require({
          message: 'You will need to be re-login if successful',
          header: 'Confirmation',
          icon: 'bi bi-question-diamond',
          accept: () => {
            this.axios.post(`https://${this.$store.state.ip}/update-email`,
              {
                email: this.email,
              }, {
                headers: {
                  Authorization: this.$store.state.token,
                },
              }).then((response) => {
              this.state.email = response.data.email;
              this.getUserData();
              this.toast.success(response.data.Message, {
                autoHideDelay: 5000,
                closeOnClick: true,
              });
              this.logout();
            }).catch((error) => {
              if (error.response.status === 403) {
                this.refreshToken();
                this.checkEmail();
              } else {
                this.toast.error(error.response.data.Message, {
                  autoHideDelay: 5000,
                  closeOnClick: true,
                });
              }
            });
          },
        });
      }
    },
    checkCurrentPassword() {
      this.password$.$validate();
      if (!this.password$.$error) {
        this.$confirm.require({
          message: 'You will need to be re-login if successful',
          header: 'Confirmation',
          icon: 'bi bi-question-diamond',
          accept: () => {
            this.axios.post(`https://${this.$store.state.ip}/update-password`,
              {
                current_password: this.state.password,
                password: this.state.newPassword,
              }, {
                headers: {
                  Authorization: this.$store.state.token,
                },
              }).then((response) => {
              this.toast.success(response.data.Message, {
                autoHideDelay: 5000,
                closeOnClick: true,
              });
              this.logout();
              this.state.password = '';
              this.state.newPassword = '';
              this.getUserData();
            }).catch((error) => {
              if (error.response.status === 403) {
                this.refreshToken();
                this.checkCurrentPassword();
              } else {
                this.toast.error(error.response.data.Message, {
                  autoHideDelay: 5000,
                  closeOnClick: true,
                });
              }
            });
          },
        });
      }
    },
    logout() {
      this.$router.push('/login');
      this.$store.commit('setToken', null);
      this.$store.commit('setUser', null);
      this.$store.commit('setRefreshToken', null);
    },
  },
  created() {
    if (this.$store.state.token === 'null' || this.$store.state.token === '') {
      this.$router.push('/login');
    }
    this.getUserData();
  },
};
</script>

<style scoped>
#ProfileForm {
  font-family: 'Uni Sans Heavy',serif;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  min-height: 100vh;
}
.card{
  width: 25rem;
}
.card-header {
  text-align: center;
}
.form-group {
  margin-bottom: 20px;
}
.col {
  /*border: 1px solid black;*/
}
</style>
