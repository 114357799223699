<template>
  <ProfileForm></ProfileForm>
</template>

<script>
import ProfileForm from '@/components/Profile/ProfileForm.vue';

export default {
  name: 'Profile',
  components: { ProfileForm },

};
</script>

<style scoped>

</style>
